var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":'fade-' + _vm.animate + '-' + _vm.keyframe}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.show),expression:"state.show"}],staticClass:"letter",style:({
      fontSize: _vm.fontHeight + 'px',
      height: _vm.fontHeight + 'px',
      width: _vm.fontHeight / 2 + 'px',
      left: _vm.x + 'px',
      top: _vm.y + 'px',
      transitionProperty: 'top, left, font-size',
      transitionDuration: 1 + 's',
      transitionTimingFunction: _vm.easingFunction,
      transitionDelay: _vm.transitionDelay
    })},[_vm._v(" "+_vm._s(_vm.char)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }