<template>
  <div id="contact">
    <div style="display: flex; flex-direction: column; align-items: center">
      <img
        src="../assets/icon.jpg"
        alt="Icon"
        style="width: 100px; height: 100px"
      />
      <p style="font-size: 2em">Takaaki Sawa</p>
    </div>
    <dl>
      <div class="flex-item">
        <dt>2018 〜 2020</dt>
        <dd>京都大学情報学研究科通信情報システム専攻 大木研究室</dd>
      </div>
      <div class="flex-item">
        <dt>2020 〜 2021</dt>
        <dd>ヤフー株式会社 Engineer</dd>
      </div>
      <div class="flex-item">
        <dt>2021 〜 現在</dt>
        <dd>株式会社グラファー Product Manager</dd>
      </div>
    </dl>
    <div style="display: flex; justify-content: center; margin-top: 40px">
      <div
        class="sns-links"
        style="display: flex; justify-content: space-between; max-width: 400px"
      >
        <a
          href="https://x.com/adshidtadka"
          target="_blank"
          style="margin-right: 5px"
        >
          <img
            src="../assets/x.png"
            alt="Twitter"
            style="width: 50px; height: 50px; margin-right: 5px"
          />
        </a>
        <a
          href="https://github.com/adshidtadka"
          target="_blank"
          style="margin-right: 5px"
        >
          <img
            src="../assets/github.svg"
            alt="Github"
            style="width: 50px; height: 50px"
          />
        </a>
        <a
          href="https://facebook.com/adshidtadka"
          target="_blank"
          style="margin-right: 5px"
        >
          <img
            src="../assets/facebook.svg"
            alt="Facebook"
            style="width: 50px; height: 50px"
          />
        </a>
        <a
          href="https://instagram.com/adshidtadka"
          target="_blank"
          style="margin-right: 5px"
        >
          <img
            src="../assets/instagram.svg"
            alt="Instagram"
            style="width: 50px; height: 50px"
          />
        </a>
        <a href="https://youtube.com/@adshidtadka" target="_blank">
          <img
            src="../assets/youtube.svg"
            alt="Youtube"
            style="width: 50px; height: 50px"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      show: true,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#contact {
  margin: 50px auto;
  height: 100%;
  dl {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;
    flex-direction: column;
  }
  dt,
  dd {
    margin: 0;
    padding: 5px;
  }
  .flex-item {
    display: flex;
  }
}
</style>
