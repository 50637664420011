<template>
  <b-container id="app" fluid="md">
    <CycleLoader v-show="loading"></CycleLoader>
    <Header></Header>
    <transition :name="transitionName" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
  </b-container>
</template>

<script>
import Header from "./components/Header";
import CycleLoader from "./components/CycleLoader";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Header,
    CycleLoader,
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
};
</script>

<style lang="scss">
html,
body {
  font-family: "M PLUS 1p", sans-serif;
  background: radial-gradient(circle, #222, #0e0e0e);
  height: 100%;
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    margin: 0 auto;
    color: #e5e5e5;
    height: auto;
    min-height: 100%;
    position: relative;
  }
}
a,
a:hover {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

</style>
