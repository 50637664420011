<template>
  <div id="about">
    <h2 class="second-headline">Development Style</h2>
    <h3>プロダクトとは課題解決の手段である</h3>
    <p>
      メッセージングサービスのLINE、QRコード決済サービスのPayPay、これらのサービスは今の生活になくてならないような存在になりつつあります。しかしこれらのサービスはすごい機能が搭載されているというだけで使われているわけではありません。
    </p>
    <p>
      LINEは「友人に対して手軽に連絡を取る」という目的において最高の手段です。しかし、いくら便利でも相手がLINEをインストールしていなければメールに切り替えるかもしれませんし、大した用事ではなく連絡を諦めるかもしれません。PayPayは「現金を使わずに決済する」という目的を達成しようとしたときに、導入店舗の多さから選ぶ方は多いでしょう。しかし、QUICPayが使えるならそちらのが手軽かもしれませんし、決済できるなら現金でいいやとなる場合もあるでしょう。
    </p>

    <p>
      このように深くユーザーに浸透しているプロダクトであっても代替手段はいくらでも存在します。ソフトウェアプロダクトであるならば、プロダクトが影響を及ぼせる情報空間とユーザーが生きる物理空間には隔たりがあるため、代替手段を選択するコストはなお低くなります。
    </p>
    <p>
      そんな代替手段が蔓延る状況下においても使ってもらえるプロダクトとは、ユーザーの課題をエレガントに解決できて、しかもそれがユーザーに理解して利用してもらえる形になってる必要があります。
    </p>
    <p>
      開発に集中するがあまり「手段の目的化」に陥らないように、プロダクトが物理空間におけるユーザーの課題を解決できるかどうかを常に問い続けることは、プロダクト開発において重要と考えます。
    </p>
    <h3>プロダクト開発とはコンピューターが持つ汎用性を削ぎ落とす作業である</h3>
    <p>
      プロダクトは課題解決の手段なので、その手段が持つ機能、つまり解決できる課題は多ければ多いほどよい、と考えがちです。しかし、ここに落とし穴があります。
    </p>
    <p>
      電卓は基本的な四則演算を行うのに便利であり、小学生でも使えるプロダクトです。しかしオフィスワーカーにとっては、関数計算やデータの可視化が必要であり、電卓では目的を達成できません。そこで、電卓より汎用的な機能を持つ表計算ソフトが発明されました。表計算ソフトは電卓よりも多くの課題を解決できますが、小学生が使うのは難しくなるでしょう。
    </p>
    <p>
      表計算ソフトが使われるようになると、今度は表計算ソフトではできない複雑な演算やビックデータの処理なんかが求められていきます。こうした汎用性の要望に答え続けると、最終的に全てのプロダクトはコンピューターそのものになり、ユーザーは自分で好きにコードを書けばいいという極論に行き着きついてしまいます。
    </p>
    <p>
      コンピューターは情報空間におけるあらゆる課題を解決できますが、プロダクトとしてコンピューターをそのままユーザーに提供することはありません。ユーザーやユーザーが解決したい課題に合わせて、コンピューターが持つ汎用性を削ぎ落としていくことがプロダクト開発、と捉えることができます。その削ぎ落とした結果が、表計算ソフトだったり電卓だったりするわけです。
    </p>
    <p>
      汎用性を求めるがあまり「機能の肥大化」に陥ることがないように、ユーザーやユーザーが解決したい課題に対してどの程度の汎用性が必要とされているかを吟味することはプロダクト開発において重要であると考えます。
    </p>
    <h3>
      よいプロダクト開発とはデリバリーパフォーマンスを高いレベルで継続することである
    </h3>
    <p>
      プロダクトとは課題解決の手段なので、プロダクトの価値は解決したユーザーの課題の量や質によって評価できます。よいプロダクト開発とはよいプロダクトが開発できたかということなので、プロダクト開発を評価するには実際にプロダクトが提供した価値の総量を測るべきなのですが、プロダクトのユーザーが課題を解決できるかどうかは、実際にプロダクトを使ってみてもらわないことにはわかりません。そこで、先行指標としてデリバリーパフォーマンスが重要と考えます。
    </p>
    <p>
      デリバリーパフォーマンスが高いということは、プロダクト開発の最初の工程である課題発見から始まって、要件定義、設計、実装、テスト、デプロイ、そして運用に至るまでの時間が短いということになります。この時間が短ければ短いほどプロダクトがユーザーの課題を解決するかどうかを頻度高く試せますし、実際にユーザーの課題を解決できるとなったときにより長い時間ユーザーに価値を届けられることになります。また、高いデリバリーパフォーマンスは平均復旧時間を短くすることにもつながります。
    </p>
    <p>
      デリバリーパフォーマンスを重要視するにあたって、短期的なデリバリーパフォーマンスに重きを置きすぎると長期的なデリバリーパフォーマンスに悪影響を及ぼすことにも注意が必要です。
    </p>
    <p>
      リリース日が決まっているプロジェクト型の開発において、工数から逆算して課題発見、要件定義の工程が蔑ろにされがちです。プロダクトの情報空間における振る舞いは物理空間の写像であり、物理空間における課題発見や物理空間と情報空間を紐づける要件定義がいい加減だと、その後の設計、実装フェーズにおいてプロダクトの振る舞いが物理空間と乖離してしまい、それがプロダクトの負債となります(あえて負債を借りる意思決定をする場合もありますが)。逆にユーザーの課題がプロダクトにおいて表現豊かに実装されていれば、後にプロダクトに変更を加える工数は大幅に削減できます。
    </p>
    <p>
      実際にプロダクトが動作するために必要のないテストの工程も蔑ろにされがちです。テストがないということは、実装されたコードの正しい振る舞いが決まっていないということになります。のちにプロダクトに変更を加える際に後方互換性を保ったまま変更しろと言われても、そもそも今の状態の正しい振る舞いが決まっていないのであれば後方互換性もなにもないでしょう。逆にテスト、特に自動テストがあれば、後方互換性を保ったままプロダクトに変更を加えられたことに自信が生まれ、変更を加えやすくなります。
    </p>
    <p>
      これらの落とし穴に気をつけて、将来のデリバリーパフォーマンスが犠牲になりすぎない程度に現在のデリバリーパフォーマンスを高いレベルに引き上げて保ち続けることがよい開発であると考えます。
    </p>
    <h2 class="second-headline">Skill Set</h2>

    <b-row
      v-for="skill in skills"
      :key="skill.name"
      class="skill-row"
      align-h="center"
      align-v="center"
    >
      <b-col lg="3">
        <b-img
          class="skill-img"
          width="40px"
          :src="require('../assets/' + skill.name + '.png')"
        />
        <span>{{ skill.formalName }}</span>
      </b-col>
      <b-col lg="7">
        <k-progress
          bg-color="#363636"
          color="#e5e5e5"
          cut-color="#363636"
          :percent="skill.percent"
          :line-height="12"
        ></k-progress>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KProgress from "k-progress";

export default {
  name: "About",
  data() {
    return {
      skills: [
        { formalName: "HTML", name: "html", percent: 70 },
        { formalName: "CSS", name: "css", percent: 70 },
        { formalName: "JavaScript", name: "js", percent: 70 },
        { formalName: "TypeScript", name: "ts", percent: 80 },
        { formalName: "Node.js", name: "nodejs", percent: 70 },
        { formalName: "Deno", name: "deno", percent: 30 },
        { formalName: "WordPress", name: "wordpress", percent: 40 },
        { formalName: "React", name: "react", percent: 80 },
        { formalName: "Next.js", name: "nextjs", percent: 70 },
        { formalName: "Vue.js", name: "vuejs", percent: 60 },
        { formalName: "Angular", name: "angular", percent: 60 },
        { formalName: "Swift", name: "swift", percent: 30 },
        { formalName: "Flutter", name: "flutter", percent: 20 },
        { formalName: "Python", name: "python", percent: 70 },
        { formalName: "C++", name: "cpp", percent: 60 },
        { formalName: "Clojure", name: "clojure", percent: 20 },
        { formalName: "Scala", name: "scala", percent: 20 },
        { formalName: "Go", name: "go", percent: 80 },
        { formalName: "PHP", name: "php", percent: 40 },
        { formalName: "Ruby", name: "ruby", percent: 30 },
        { formalName: "Java", name: "java", percent: 30 },
        { formalName: "GraphQL", name: "graphql", percent: 60 },
        { formalName: "Hasura", name: "hasura", percent: 40 },
        { formalName: "gRPC", name: "grpc", percent: 30 },
        { formalName: "MySQL", name: "mysql", percent: 60 },
        { formalName: "PostgreSQL", name: "postgres", percent: 70 },
        { formalName: "MongoDB", name: "mongo", percent: 60 },
        { formalName: "Kubernetes", name: "k8s", percent: 60 },
        { formalName: "Helm", name: "helm", percent: 30 },
        { formalName: "Docker", name: "docker", percent: 70 },
        { formalName: "Terraform", name: "tf", percent: 50 },
        { formalName: "Argo CD", name: "argocd", percent: 60 },
        { formalName: "Chef", name: "cf", percent: 30 },
        { formalName: "AWS", name: "aws", percent: 80 },
        { formalName: "Google Cloud", name: "gcp", percent: 50 },
        { formalName: "Azure", name: "azure", percent: 60 },
        { formalName: "OpenAI", name: "openai", percent: 30 },
        { formalName: "Vim", name: "vim", percent: 70 },
      ],
    };
  },
  components: {
    KProgress,
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/rfs/scss";

#about {
  padding: 0 0 50px 0;
  .second-headline {
    text-align: center;
    margin-bottom: 50px;
  }
  .skill-row {
    margin: 10px auto;
    .skill-img {
      width: 40px;
      margin-right: 15px;
    }
  }
}
</style>
